// funding-slider
.funding-slider {
  margin-bottom: 2rem;
  @media screen and (min-width: $md) {
    padding: 0 5rem;
  }

  & .splide__track {
    border-radius: 15px;
  }

  &__slider {
    flex: 0 0 auto;
  }

  &__item {
    opacity: 0.8;
    transition: opacity ease 0.3s;

    & label {
      background-color: #f1f5fc;
      padding: 1rem;
      border-radius: 1rem;
      border: 3px solid transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      height: 100%;
      justify-items: center;
      justify-content: center;

      @media screen and (min-width: $sm) {
        padding: 1.25rem;
      }
    }

    & img {
      max-width: 65%;
      margin-bottom: 1rem;
      transition: all ease 0.2s;
      @media screen and (min-width: $sm) {
        max-width: 75%;
      }
    }

    & input {
      display: none;
    }

    & .funding-slider__information {
      display: none;
      text-align: center;
    }

    &.is-active {
      opacity: 1;

      & .funding-slider__information {
        display: block;
      }

      & label {
        border-color: var(--text-secondary);
      }
    }
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--text-descr);
    @media screen and (min-width: $sm) {
      font-size: 1.25rem;
    }
  }

  &__rate {
    margin-top: 0.5rem;
    font-size: 1.125rem;
    font-weight: 900;
    color: var(--text-primary);
    @media screen and (min-width: $sm) {
      font-size: 1.25rem;
    }
  }

  &__confirmation {
    margin-top: 0.5rem;
    display: inline-block;
    padding: 0.125rem 0.75rem;
    border-radius: 15px;
    background-color: #9caed1;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 400;
    @media screen and (min-width: $sm) {
      font-size: 0.75rem;
    }
  }

  &__arrow {
    display: none;

    @media screen and (min-width: $md) {
      position: absolute;
      top: 0;
      height: 100%;
      padding: 1rem 1.5rem;
      background-color: #f1f5fc;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      cursor: pointer;
      transition: background-color ease 0.15s;

      &:hover {
        background-color: #e3e7ee;
      }

      & svg {
        fill: var(--bg-secondary);
        max-width: 1.25rem;
        max-height: 1.25rem;
      }

      &_prev {
        left: 0;
      }

      &_next {
        right: 0;
        & svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
