// quotes
.quotes {
  background-color: #003395;
  padding: 3rem 0;
  @media screen and (min-width: $lg) {
    padding: 3rem 0 7rem;
  }

  &__lay {
    @media screen and (min-width: $lg) {
      margin-top: -12rem;
    }
  }

  &__container {
    padding: 0;
    @media screen and (min-width: $lg) {
      padding: 0 20px;
    }
  }
}

.quotes-nav {
  margin-bottom: 2rem;
  @media screen and (min-width: $lg) {
    margin-bottom: unset;
  }

  &__list {
    display: flex;
    align-items: baseline;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 0 20px;
    @media screen and (min-width: $lg) {
      padding: unset;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    padding-bottom: 5px;
    @media screen and (min-width: $lg) {
      padding-bottom: unset;
    }
    & span {
      color: #3dfff5;
      position: relative;
      margin: 0 1rem;
      white-space: nowrap;
      text-transform: uppercase;
      line-height: 1;
      font-weight: 700;
      flex-grow: 1;
      font-size: 0.936rem;
      text-align: center;
      display: flex;
      align-content: center;
      justify-content: center;
      @media screen and (min-width: $lg) {
        font-size: 1rem;
      }

      &:after {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: -0.25rem;
        width: 100%;
        height: 2px;
        background-color: #3dfff5;
      }
    }

    &_active span {
      color: var(--text-white);
      font-size: 1.125rem;

      &:after {
        background-color: var(--bg-secondary);
        height: 3px;
      }
    }

    @media screen and (min-width: $lg) {
      color: var(--text-white);
      padding: 1.5rem 2.5rem 1.25rem;
      margin: 0;
      border-radius: 25px 25px 0 0;
      cursor: pointer;

      & span:after {
        display: none;
      }

      &:hover {
        background-color: rgba(255, 255, 255, .2);
      }

      &_active, &_active:hover, &_active:focus {
        background-color: var(--text-white);
        & span {
          color: var(--text-secondary);
        }
      }

    }
  }
}


// quote
.quote {
  height: 100%;
  background-color: var(--bg-white);
  padding: 1.25rem;
  border-radius: 15px;
  @media screen and (min-width: $lg) {
    padding: 2rem;
    border-radius: 0 0 25px 25px;
  }

  &__item {
    width: 100%;
    margin-bottom: 2rem;
    @media screen and (min-width: $lg) {
      margin-bottom: 0;
    }
  }

  &__head {
    font-family: 'Gilroy';
    font-weight: 700;
    display: flex;
    align-content: center;
    align-items: center;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
  }

  &__left {
    font-size: 1.25rem;
    flex: 0 0 50%;
    font-weight: 900;
  }

  &__right {
    font-size: 0.875rem;
    line-height: 1.4;
    text-align: center;
    flex: 0 0 50%;
  }

  &__blocks {
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    position: relative;
    @media screen and (min-width: $lg) {
      border-radius: 15px;
    }
  }

  &__spread {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -2px;

    & span {
      margin: 0 auto;
      color: var(--text-primary);
      font-size: 0.825rem;
      font-weight: 700;
      padding: 0.25rem 0.5rem;
      border-radius: 0 0 10px 10px;
      background-color: var(--bg-white);
    }
  }

  &__block {
    color: var(--text-white);
    font-weight: 900;
    flex: 50%;
    padding: 1rem 0.5rem 0.325rem 0.5rem;

    &_down {
      background: rgb(240, 29, 65);
      background: linear-gradient(0deg, rgba(240, 29, 65, 1) 0%, rgba(255, 93, 65, 1) 100%);
    }

    &_up {
      background: rgb(97, 172, 30);
      background: linear-gradient(0deg, rgba(97, 172, 30, 1) 0%, rgba(189, 233, 69, 1) 100%);
    }
  }

  &__block-title {
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
  }

  &__digits {
    display: flex;
    align-items: baseline;
    justify-content: center;
    line-height: 1;
    position: relative;

    &_center {
      font-size: 1.5rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      @media screen and (min-width: $lg) {
        font-size: 2.25rem;
      }
    }
  }

  &__a {
    font-size: 1rem;
    font-weight: 900;
  }

  &__b {
    font-size: 5rem;
    font-weight: 900;
  }

  &__c {
    font-size: 1rem;
    font-weight: 900;
  }
}
