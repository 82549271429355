// forms
.form {
  color: var(--text-primary);
  &_cg {
    color: #9aa8c2;
  }

  &__group {
    margin-bottom: 2.125rem;
    width: 100%;
    //padding: 0 1.5rem;
    @media screen and (min-width: $lg) {
      margin-bottom: 2.5rem;
      //padding: unset;
    }
  }

  &__label {
    font-size: 0.625rem;
    font-family: 'Gilroy';
    font-weight: 900;
    text-transform: uppercase;
    color: var(--text-descr-form);
    margin-bottom: 0.5rem;
    display: block;
    letter-spacing: 0.075rem;
    padding: 0 1.5rem;
    @media screen and (min-width: $lg) {
      font-size: 0.875rem;
      margin-bottom: 1rem;
      padding: 0 2rem;
    }

    &_blue {
      color: var(--text-primary);
    }
  }

  & textarea {
    min-width: 100%;
    max-width: 100%;
  }

  &__control {
    font-size: 1.125rem;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    background-color: var(--bg-white);
    width: 100%;
    outline: none;
    color: inherit;
    text-align: inherit;
    @media screen and (min-width: $lg) {
      font-size: 1.25rem;
      padding: 1rem 2rem;
      border-radius: 15px;
    }

    &_grey {
      background-color: #f1f5fc;
    }
  }
}


.select {
  position: relative;

  & svg {
    position: absolute;
    top: calc(50% - 1rem);
    right: 2rem;
    fill: var(--bg-secondary);
  }
}

.select select {
  display: none;
}

.select-selected {
  font-size: 1.125rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-radius: 10px;
  border-color: transparent;
  background-color: #f1f5fc;
  width: 100%;
  outline: none;
  color: inherit;
  text-align: inherit;
  position: relative;
  z-index: 50;
  @media screen and (min-width: $lg) {
    font-size: 1.25rem;
    padding: 1rem 2rem;
    border-radius: 15px;
  }
}

.select-selected:after {
  position: absolute;
  content: "";
  top: calc(50% - 0.25rem);
  right: 2rem;
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-color: var(--bg-secondary) transparent transparent transparent;
}

.select-selected.select-arrow-active {
  &:after {
    transform: rotate(180deg);
    top: calc(50% - 0.75rem);
  }
}

.select-items div,
.select-selected {
  //color: #ffffff;
  //padding: 8px 16px;
  //border: 1px solid transparent;
  //border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  //cursor: pointer;
}

.select-items {
  position: absolute;
  background-color: #fff;
  top: calc(100% - 2rem);
  left: 0;
  right: 0;
  z-index: 49;
  border: 1px solid #f1f5fc;
  border-radius: 10px;
  overflow: hidden;
  padding: 2rem 2rem 0;

  & div {
    font-size: 1.125rem;
    padding: 1rem 0;
    font-weight: 600;
    position: relative;
    border-bottom: 1px solid #7ca1d7;
    cursor: pointer;
    color: #9aa8c2;
  }
}

.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  //background-color: rgba(0, 0, 0, 0.05);
}
