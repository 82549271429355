// register
.register {
  &__container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;

    @media screen and (min-width: $sm) {
      padding: 0 35px;
    }

    @media screen and (min-width: $lg) {
      padding: 0 20px;
    }
  }

  &__wrap {
    padding: 0 0 1rem 0;

    @media screen and (min-width: $sm) {
      border: 4px solid #ebeef3;
      border-radius: 1rem;
      padding: 4rem 2rem;
    }

    &_deposit {
      border: none;
      padding: 0;
      @media screen and (min-width: $sm) {
        padding: 2rem 2rem;
      }
    }
  }

  &__form {
    max-width: 32rem;
    margin: 0 auto;
    padding: 0 35px;

    @media screen and (min-width: $sm) {
      padding: 0;
    }
  }

  &__step {
    display: flex;
    align-items: center;
    color: var(--text-primary);
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.75rem;

    @media screen and (min-width: $sm) {
      margin-bottom: 1rem;
      font-size: 1.375rem;
    }
  }

  &__number {
    font-size: 1.5rem;
    font-family: 'Gilroy';
    font-weight: 700;
    border-radius: 50%;
    color: var(--text-secondary);
    border: 1px solid var(--bg-secondary);
    flex: 0 0 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;

    @media screen and (min-width: $sm) {
      margin-right: 1rem;
      flex: 0 0 3rem;
      height: 3rem;
      font-size: 2rem;
    }
  }

  &__mail {
    margin-top: 2rem;
    position: relative;

    @media screen and (min-width: $sm) {
      margin-top: 2rem;
    }

    & input {
      font-size: 1.25rem;
      font-weight: 600;
      width: 100%;
      color: #9aa8c2;
      background-color: #f1f5fc;
      padding: 0.75rem 3rem 0.75rem 1rem;
      border-radius: 0.75rem;
      border: none;
      outline: none;

      @media screen and (min-width: $sm) {
        font-size: 1.5rem;
        padding: 0.875rem 4.5rem 0.875rem 1.5rem;
        border-radius: 1rem;
      }
    }

    & button {
      background-color: var(--bg-secondary);
      border-radius: 0.75rem;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.375rem;
      position: absolute;
      right: 0.375rem;
      top: 0.375rem;
      height: calc(100% - 0.75rem);
      cursor: pointer;

      @media screen and (min-width: $sm) {
        border-radius: 1rem;
      }

      & svg {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        fill: var(--bg-white);
      }
    }
  }
}

// register-description
.register-description {
  &__wrap {
    border: 4px solid #ebeef3;
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
    font-family: 'Gilroy';
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--text-primary);

    @media screen and (min-width: $sm) {
      font-size: 1.25rem;
      padding: 2rem;
    }

    & span {
      color: var(--text-secondary);
    }
  }
}

// register-slider
.register-slider {
  margin-bottom: 2rem;
  position: relative;
  border-radius: 1rem;

  @media screen and (min-width: $sm) {
    margin-bottom: 3rem;

    &::after,
    &::before {
      content: ' ';
      position: absolute;
      height: 100%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 58%, rgba(255, 255, 255, 0) 100%);
      width: 7.5rem;
      top: 0;
      left: 2rem;
      z-index: 2;
    }

    &::before {
      transform: rotate(180deg);
      right: 2rem;
      left: auto;
    }
  }

  &__nav {
    display: none;

    @media screen and (min-width: $sm) {
      position: absolute;
      z-index: 3;
      height: 100%;
      top: 0;
      padding: 1rem 1.5rem;
      background-color: #f1f5fc;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      cursor: pointer;
      transition: background-color ease 0.15s;

      &:hover {
        background-color: #e3e7ee;
      }

      & svg {
        fill: var(--bg-secondary);
        max-width: 1.25rem;
        max-height: 1.25rem;
      }

      &_next {
        right: 0;

        & svg {
          transform: rotate(180deg);
        }
      }

      &_prev {
        left: 0;
      }
    }
  }

  & .splide__slide {
    & label {
      display: block;
      background-color: #f1f5fc;
      padding: 0.5rem;
      border-radius: 1rem;
      border: 3px solid transparent;
      text-align: center;

      @media screen and (min-width: $sm) {
        padding: 1rem;
      }
    }

    &.is-active {
      & label {
        border-color: var(--bg-secondary);
      }
    }

    & input {
      display: none;

      &.checked + label {
        background-color: transparent;
        border: 3px solid var(--bg-secondary);
      }
    }

    & img {
      margin: auto;
    }
  }

  &__slide-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: #4989d6;

    @media screen and (min-width: $sm) {
      font-size: 1.25rem;
    }
  }
}
