// deposit
.deposit {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    flex: calc(50% - 3rem);
    text-align: center;
    margin-bottom: 4rem;
    padding: 0 2rem;

    & img {
      display: block;
      margin: 0 auto 1rem;
      max-width: 15rem;
      @media screen and (min-width: $lg) {
        margin: 0 auto 2rem;
        max-width: 20rem;
      }
    }
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
    @media screen and (min-width: $lg) {
      font-size: 2rem;
    }
  }

  &__subtitle {
    font-size: 0.875rem;
    font-weight: 500;
    color: #3879c8;
    @media screen and (min-width: $lg) {
      font-size: 1.25rem;
    }
  }
}
