// account
.account {
  background-color: #0140b6;
  text-align: center;
  padding: 0 0 5.5rem 0;
  @media screen and (min-width: $lg) {
    padding: 0 0 10rem 0;
  }

  &__table {
    @media screen and (min-width: $md) {
      padding: 2rem 20% !important;
    }
  }

  &__lay {
    padding-top: 3.5rem;
    background: url("../static/bg_mobile_mask.png") center center, linear-gradient(119deg, rgba(3,99,224,1) 0%, rgba(0,67,187,1) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (min-width: $lg) {
      padding-top: 7rem;
      background: url("../static/bg-account.png") center center, linear-gradient(119deg, rgba(3,99,224,1) 0%, rgba(0,67,187,1) 100%);
    }
  }

  &__title {
    font-size: 2.5rem;
    font-family: 'Gilroy';
    font-weight: 900;
    color: var(--text-white);
    margin-bottom: 1rem;
    letter-spacing: -0.05rem;
    line-height: 1.2;
    @media screen and (min-width: $lg) {
      letter-spacing: -0.15rem;
      font-size: 3.5rem;
      margin-bottom: 2.25rem;
    }
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-descr-form);
    letter-spacing: -0.06rem;
    margin-bottom: 1.75rem;
    @media screen and (min-width: $lg) {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__form {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (min-width: $lg) {
      max-width: 52.5rem;
    }

    &-col {
      flex: 0 0 100%;

      @media screen and (min-width: $lg) {
        &_small {
          flex: 0 0 46%;
        }
      }
    }
  }

  &__btn {
    width: 100%;
    margin-bottom: -1.5rem;
    @media screen and (min-width: $lg) {
      margin-top: 2.75rem;
      margin-bottom: -2rem;
    }
  }
}
