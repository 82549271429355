// blocks
.block {
  border: 2px solid #ebeef3;
  border-radius: 15px;
  padding: 4rem 2rem;
  text-align: center;

  &_grey {
    background-color: #f1f5fc;
    border-color: #f1f5fc;
  }

  &__title {
    color: var(--text-primary);
    font-family: 'Gilroy';
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
    margin: 0 auto 3rem;
    line-height: 1.2;
    @media screen and (min-width: $lg) {
      font-size: 3rem;
      margin: 0 auto 4rem;
    }
  }

  &__list {
    max-width: 220px;
    margin: 0 auto 2.5rem;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    color: var(--text-descr);
    font-size: 1.125rem;
    font-weight: 500;
    text-align: left;
    margin: 0 auto 1.25rem;
  }

  & svg {
    margin-right: 1rem;
    flex: 0 0 22px;
    margin-top: 0.25rem;
  }
}

