// top
.top {
  background: url("../static/bg_m.jpg") center center no-repeat #0341c7;
  background-size: cover;
  padding: 2rem 0 5rem;
  max-width: 100vw;
  text-align: center;
  @media screen and (min-width: $lg) {
    overflow-x: hidden;
    padding: 2rem 0 16rem;
    text-align: unset;
    background: url("../static/bg.jpg");
    background-size: cover;
  }

  &_platforms {
    background-color: #0046be;
    background-image: url("../static/bg-header-platforms.png"), url("../static/bg_m.jpg");
    background-size: 320px, cover;
    background-position: top 13rem center, center center;
    background-repeat: no-repeat, no-repeat;
    padding: 2rem 0 0;
    overflow: unset;
    @media screen and (min-width: $lg) {
      background-image: url("../static/bg-header-platforms.png"), url("../static/bg.jpg");
      background-size: 450px, cover;
      background-position: bottom right calc(50% - 300px), center center;
      padding: 2rem 0 0;
    }
    @media screen and (min-width: $xxl) {
      background-size: 500px, cover;
      background-position: bottom right calc(50% - 350px), center center;
    }

    .top__title {
      margin: 2.5rem auto 2rem;
      @media screen and (min-width: $lg) {
        margin: 7rem 0 1rem;
      }
      @media screen and (min-width: $xl) {
        margin: 9rem 0 2rem;
      }
    }
  }

  &_funding {
    background-color: #0046be;
    background-image: url("../static/bg-header-funding.png"), url("../static/bg.jpg");
    background-size: 720px, cover;
    background-position: bottom center, center center;
    background-repeat: no-repeat, no-repeat;
    padding: 2rem 0 25rem;
    @media screen and (min-width: $lg) {
      background-size: 1050px 693px, cover;
      background-position: bottom right calc(50% - 200px), center center;
      padding: 2rem 0 9rem;
    }
    @media screen and (min-width: $xxl) {
      background-size: 1100px 726px, cover;
      background-position: bottom right calc(50% - 300px), center center;
    }

    .top__title {
      margin: 2.5rem auto 2rem;
      @media screen and (min-width: $lg) {
        margin: 7rem 0 1rem;
      }
      @media screen and (min-width: $xl) {
        margin: 9rem 0 2rem;
      }
    }

    & .btn {
      display: none;
      @media screen and (min-width: $lg) {
        display: inline-block;
      }
    }
  }

  &_accounts {
    background: url("../static/bg-header-accounts_mobile.png") no-repeat #0046be;
    background-position: bottom center;
    background-size: 720px;
    padding: 2rem 0 0;
    @media screen and (min-width: $lg) {
      background: url("../static/bg-header-accounts.png") no-repeat #0046be;
      background-position: bottom right calc(50% - 300px);
      background-size: 1100px 750px;
      padding: 2rem 0 9rem;
    }

    .top__title {
      margin: 2.5rem auto 2rem;
      @media screen and (min-width: $lg) {
        margin: 7rem 0 1rem;
      }
      @media screen and (min-width: $xl) {
        margin: 9rem 0 2rem;
      }
    }
  }

  &_deposit {
    background: url("../static/deposit.png") no-repeat #0046be;
    background-position: bottom calc(50% - 70px) center;
    background-size: 720px;
    padding: 2rem 0 0;

    @media screen and (min-width: $lg) {
      background: url("../static/deposit.png") no-repeat #0046be;
      background-position: bottom 50% right calc(50% - 250px);
      background-size: 1000px;
      padding: 2rem 0 9rem;
    }

    @media screen and (min-width: $xl) {
      background: url("../static/deposit.png") no-repeat #0046be;
      background-position: bottom calc(50% - 50px) right calc(50% - 320px);
      background-size: 1000px;
      padding: 3rem 0 9rem;
    }

    @media screen and (min-width: $xxl) {
      background: url("../static/deposit.png") no-repeat #0046be;
      background-position: bottom calc(50% - 50px) right calc(50% - 350px);
      background-size: 1100px;
      padding: 2rem 0 9rem;
    }
  }

  &__title {
    font-size: 2rem;
    font-family: 'Gilroy';
    font-weight: 900;
    color: var(--text-white);
    max-width: 800px;
    line-height: 1.15;
    margin: 2.5rem auto 1.125rem;
    @media screen and (min-width: $lg) {
      margin: 8rem 0 1rem;
      font-size: 3.5rem;
      max-width: 575px;
    }
    @media screen and (min-width: $xl) {
      margin: 12rem 0 2rem;
      font-size: 3.5rem;
      max-width: 590px;
    }
  }

  &__subtitle {
    font-size: 0.875rem;
    font-weight: 500;
    color: #c0fffc;
    max-width: 200px;
    margin: 0 auto;
    @media screen and (min-width: $lg) {
      font-size: 1.5rem;
      letter-spacing: -0.075rem;
      max-width: unset;
      margin: unset;
    }

    &_full {
      max-width: 20rem;
      @media screen and (min-width: $lg) {
        max-width: unset;
      }
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__left {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: $lg) {
      flex: 0 0 50%;
      padding-right: 4rem;
      align-items: flex-start;
    }
  }

  &__right {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    margin-top: 14rem;
    position: relative;
    @media screen and (min-width: $lg) {
      flex: 0 0 50%;
      margin-top: unset;
    }
  }

  .top__advs {
    display: flex;
    justify-content: space-between;
    margin: 2.25rem auto 0;
    width: 100%;
    max-width: 400px;
    @media screen and (min-width: $lg) {
      margin-top: 4rem;
      max-width: unset;
    }
  }

  .top__adv {
    font-family: 'Gilroy';
    font-weight: 700;
    color: #3dfff5;
    font-size: 1.25rem;
    line-height: 1.3;
    letter-spacing: -0.05rem;
    text-align: center;
    @media screen and (min-width: $sm) {
      font-size: 1.75rem;
    }
    @media screen and (min-width: $lg) {
      font-size: 2.5rem;
    }

    &_mobile {
      @media screen and (min-width: $lg) {
        display: none;
      }
    }

    & span {
      color: var(--text-white);
      font-size: 0.5rem;
      text-transform: uppercase;
      display: block;
      letter-spacing: 0.08rem;
      @media screen and (min-width: $sm) {
        font-size: 0.75rem;
      }
      @media screen and (min-width: $lg) {
        font-size: 1.125rem;
        letter-spacing: 0.1rem;
      }
    }
  }

  &__order-form {
    background-color: var(--bg-white);
    border-radius: 15px;
    text-align: center;
    padding: 2rem 1rem 2.75rem 1rem;
    width: 100%;
    max-width: 450px;
    position: relative;
    z-index: 2;
    @media screen and (min-width: $lg) {
      padding: 2rem 2rem 2.75rem 2rem;
      border-radius: 25px;
    }

    &-input {
      background-color: rgba(227, 233, 241, 0.4);
      border: 2px solid #e3e9f1;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-radius: 10px;
      @media screen and (min-width: $lg) {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
    }
  }

  &__order-title {
    font-size: 1.5rem;
    font-family: 'Gilroy';
    font-weight: 700;
    color: var(--text-primary);
    @media screen and (min-width: $lg) {
      font-size: 2rem;
      letter-spacing: -0.1rem;
    }
  }

  &__order-label {
    font-size: 0.625rem;
    color: var(--text-secondary);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @media screen and (min-width: $lg) {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__order-btn {
    margin: 0 auto -4.5rem;
    display: block;
    @media screen and (max-width: $lg) {
      padding: 1rem 2.75rem;
    }
  }

  &__order-bg {
    @media screen and (min-width: $lg) {
      background: url("../static/character_bg.png") center center no-repeat;
      background-size: cover;
      width: 700px;
      height: 700px;
      position: absolute;
      z-index: 1;
      top: -400px;
      right: -200px;
    }
  }

  &__order-man {
    z-index: 3;
    content: ' ';
    position: absolute;
    top: -208px;
    right: calc(50% - 150px);
    width: 300px;
    height: 230px;
    @media screen and (min-width: $lg) {
      top: -314px;
      right: -30px;
      width: 400px;
      height: 350px;
    }
    @media screen and (min-width: $xl) {
      top: -298px;
      right: -75px;
      width: 400px;
      height: 330px;
    }
  }
}

// top form
.top-form {
  width: 100%;
  max-width: 450px;
  position: relative;

  &_platform {
    margin-bottom: -3rem;
    z-index: 2;
  }

  &_deposit {
    margin-top: 20rem;
    margin-bottom: -1.5rem;
    @media screen and (min-width: $lg) {
      margin-top: 3.25rem;
    }
  }

  &_man-bg {
    margin-top: 10rem;
    margin-bottom: -1.5rem;
    @media screen and (min-width: $lg) {
      margin: 0;
    }

    &:after {
      @media screen and (min-width: $lg) {
        display: none;
      }

      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      max-width: 320px;
      height: 20rem;
      background: url("../static/bg-header-accounts-man_mobile.png") no-repeat;
      background-position: bottom 4rem center;
      background-size: contain;
    }
  }

  &__layer {
    font-family: 'Gilroy';
    font-weight: 600;
    font-size: 1rem;
    border-radius: 0.75rem;
    background-color: #fa9613;
    color: var(--text-white);
    text-transform: uppercase;
    padding: 0.5rem 0.5rem 0.75rem 0.5rem;
    margin-top: 3rem;
    position: relative;
    overflow: hidden;
    @media screen and (min-width: $lg) {
      font-size: 1.25rem;
      padding: 0.75rem 0.75rem 1rem 0.75rem;
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 0;
      width: 100%;
      height: calc(100% * 2);
      background-color: #f26b06;
      transform: rotate(-45deg) translateX(8rem) translateY(5.5rem);
    }
  }

  &__title {
    margin-left: 1rem;
    margin-bottom: 0.75rem;
    line-height: 1;
    position: relative;
    z-index: 3;
    text-align: left;
  }

  &__wrap {
    display: flex;
    position: relative;
    z-index: 3;
  }

  &__input {
    border-radius: 0.5rem;
    border: none;
    outline: none;
    color: var(--text-primary);
    font-size: inherit;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    flex: 1 1 auto;
    @media screen and (min-width: $lg) {
      padding: 0.5rem 1rem;
      border-radius: 0.75rem;
    }
  }

  &__button {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: #d65105;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    @media screen and (min-width: $lg) {
      width: 2.75rem;
      height: 2.75rem;
      padding: 0.75rem;
    }

    & svg {
      fill: var(--bg-white);
      max-width: 100%;
      max-height: 100%;
    }
  }
}

// top platform adv
.top-platform-adv {
  margin-top: 3rem;
  padding-top: 23rem;
  width: 100%;
  @media screen and (min-width: $lg) {
    padding: 0;
    margin-bottom: 2rem;
  }

  &__list {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    flex: 0 0 calc(100% / 3);
    font-family: 'Gilroy';
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--text-white);
    text-transform: uppercase;
    text-align: center;
    @media screen and (min-width: $lg) {
      font-size: 0.825rem;
      padding-right: 1rem;
    }
  }

  &__divider {
    margin: 0.75rem auto;
    background-color: #3dfff5;
    width: 1rem;
    height: 1px;
    @media screen and (min-width: $lg) {
      margin: 1rem auto;
      height: 2px;
    }
  }
}

.platform-blue-line {
  padding: 3rem 0;
  background-color: #0037a0;
  @media screen and (min-width: $lg) {
    padding: 4rem 0;
  }
}

// top account adv
.top-account-adv {
  margin-top: 1rem;
  @media screen and (min-width: $lg) {
    display: none;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    font-family: 'Gilroy';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.05rem;
    color: var(--text-white);
    flex: 0 0 50%;
    padding: 0.5rem;
    text-transform: uppercase;

    &_b {
      flex: 0 0 100%;
    }

    & span {
      display: block;
      width: 100%;
      color: #3dfff5;
      font-size: 1.375rem;
      font-weight: 900;
    }
  }
}

// header
.header-container {
  background-color: var(--bg-primary);
  padding: 2rem 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 5;

  &__auth {
    display: none;
    align-items: center;
    @media screen and (min-width: $xl) {
      display: flex;
    }
  }

  &__sign {
    font-size: 1.125rem;
    text-transform: uppercase;
    padding: 0.875rem 2.5rem;
  }

  &__login {
    padding: 0.5rem 1.75rem 0.5rem 1.75rem;
    margin-right: 2rem;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 1rem;
    border: 2px solid rgba(184, 222, 255, .3);

    &:hover {
      background-color: rgba(184, 222, 255, .3);
    }
  }

  &__burger {
    width: 3rem;
    height: 3rem;
    padding: 0.625rem;
    border: 2px solid rgba(184, 222, 255, .3);
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    cursor: pointer;
    transition: background-color ease 0.1s;
    @media screen and (min-width: $lg) {
      width: 3.375rem;
      height: 3.375rem;
      padding: 0.75rem;
    }
    @media screen and (min-width: $xl) {
      display: none;
    }

    &:hover {
      background-color: rgba(184, 222, 255, .3);
    }

    & span {
      height: 3px;
      width: 100%;
      background-color: var(--bg-white);
      border-radius: 3px;
      display: block;
      margin: 2px 0;
    }
  }
}

// logo
.logo {
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  position: relative;

  &__sub {
    flex: 0 0 100%;
    color: #c0fffc;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-size: 0.75rem;
    bottom: -0.75rem;
    font-weight: 900;
    position: absolute;
    width: 100%;
    white-space: nowrap;
    @media screen and (min-width: $lg) {
      letter-spacing: 0.2rem;
      font-size: 0.875rem;
      bottom: -1.5rem;
    }
  }

  & svg {
    fill: var(--bg-logo);
    margin-right: 0.5rem;
    width: 2rem;
    @media screen and (min-width: $lg) {
      width: 3rem;
    }
  }

  & > span {
    color: var(--text-white);
    font-family: 'Gilroy';
    font-size: 1.125rem;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 0.25rem;
    line-height: 1;
    @media screen and (min-width: $lg) {
      font-size: 1.625rem;
    }

    & > span {
      color: var(--text-secondary)
    }
  }

  &_mobile-nav span {
    color: var(--text-secondary)
  }
}
