// accordion
.accordion, .accordion-no-child {
  background-color: #f7f9fd;
  color: #457cc7;
  width: 100%;
  transition: 0.4s;

  & a {
    text-decoration: none;
    font-size: 1rem;
    color: #457cc7;

    @media screen and (min-width: $md) {
      font-size: 1.375rem;
    }
  }

  & ul {
    margin-bottom: 0;

    & li {
      padding: 0;
      &:before {
        display: none;
      }
    }
  }

  & > ul > li > a {
    display: block;
    background-color: #ffffff;
    border-bottom: 1px solid #457cc7;
    font-weight: 700;
    padding: 0.75rem 3rem 0.75rem 0;
    position: relative;

    &:after {
      position: absolute;
      top: calc(50% - 1.125rem);
      right: 1rem;
      content: '\002B';
      font-size: 1.5rem;
      color: #457cc7;

      @media screen and (min-width: $md) {
        top: calc(50% - 1.625rem);
        font-size: 2rem;
      }
    }

    &.active:after {
      content: "\2212";
    }
  }

  & > ul > li:first-child > a {
    border-top: 1px solid #457cc7;
  }

  & > ul > li > ul {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    & > li > a {
      display: block;
      padding: 0.75rem 1.5rem;
      font-weight: 400;
      font-size: 0.875rem;

      @media screen and (min-width: $md) {
        font-size: 1.25rem;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    & > li:first-child > a {
      padding-top: 1.5rem;
    }

    & > li:last-child > a {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #457cc7;
    }
  }
}
