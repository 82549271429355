// about
.about {
  padding: 2rem 0;
  @media screen and (min-width: $lg) {
    padding: 5rem 0 2rem;
  }

  & > .container {
    padding: 0;
    @media screen and (min-width: $lg) {
      padding: 0 30px;
    }
  }

  & .btn_small {
    min-width: 7.5rem !important;
    @media screen and (min-width: $lg) {
      min-width: 12rem !important;
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 4rem;

    @media screen and (min-width: $lg) {
      margin-bottom: 7rem;
    }
  }

  &__content {
    text-align: center;
    padding: 0 2rem;
    flex: 0 0 100%;
    order: 2;
    @media screen and (min-width: $lg) {
      flex: 0 0 50%;
      order: unset;
      padding-bottom: 5rem;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    order: 1;
    @media screen and (min-width: $lg) {
      flex: 0 0 50%;
      order: unset;
    }

    & img, & div {
      width: 90%;
      max-width: 25rem;
      margin-bottom: 2rem;
      @media screen and (min-width: $lg) {
        width: 100%;
        max-width: unset;
        margin-bottom: 0;
      }
    }

    & img {
      @media screen and (min-width: $lg) {
        width: 95%;
      }
    }
  }

  &__title {
    font-size: 1.625rem;
    font-weight: 700;
    font-family: 'Gilroy';
    color: var(--text-primary);
    line-height: 1.2;
    margin-bottom: 1.125rem;
    @media screen and (min-width: $lg) {
      font-size: 2.75rem;
      letter-spacing: -0.15rem;
      margin-bottom: 2.25rem;
    }
  }

  &__subtitle {
    font-size: 0.625rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    font-family: 'Gilroy';
    color: var(--text-secondary);
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    @media screen and (min-width: $lg) {
      font-size: 1.125rem;
      margin-bottom: 2rem;
    }
  }

  &__descr {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-descr);
    margin: 0 auto 1.375rem;
    max-width: 30rem;
    @media screen and (min-width: $lg) {
      font-size: 1.125rem;
      margin: 0 auto 3rem;
      max-width: unset;
    }
  }
}

#i1 {
  min-height: 30rem;
}
