// footer
.footer {
  padding: 4.5rem 0;
  text-align: center;
  @media screen and (min-width: $lg) {
    padding: 7.5rem 0;
    text-align: left;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title {
    font-size: 0.938rem;
    font-weight: 900;
    font-family: 'Gilroy';
    color: var(--text-primary);
    text-transform: uppercase;
    margin-bottom: 0.75rem;
    letter-spacing: 0.08rem;
    @media screen and (min-width: $lg) {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__left {
    flex: 0 0 100%;
    @media screen and (min-width: $lg) {
      flex: 0 0 25%;
    }
  }

  &__logo {
    font-family: 'Gilroy';
    font-size: 1.625rem;
    font-weight: 900;
    text-decoration: none;
    color: var(--bg-logo);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    margin-bottom: 1rem;
    justify-content: center;
    @media screen and (min-width: $lg) {
      justify-content: normal;
      margin-bottom: 2.75rem;
    }

      & svg {
      width: 3rem;
      fill: var(--bg-logo);
      margin-right: 0.5rem;
    }
  }

  &__descr {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-link);
    letter-spacing: -0.02rem;
    text-align: center;
    @media screen and (min-width: $lg) {
      font-size: 1rem;
      padding-right: 2rem;
      text-align: left;
    }
  }

  &__right {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: $lg) {
      flex: 0 0 71%;
    }
  }

  &__col3 {
    flex: 0 0 100%;
    @media screen and (min-width: $lg) {
      flex: 0 0 calc(100% / 3);
    }
  }

  &__col-auto {
    flex: 0 0 100%;
    @media screen and (min-width: $lg) {
      flex: 0 0 calc(100% / 3 * 2);
    }
  }

  &__risks {
    margin-top: 2.25rem;
    font-size: 0.5rem;
    @media screen and (min-width: $lg) {
      margin-top: 2.5rem;
      font-size: 0.585rem;
    }
  }

  &__risk {
    color: var(--text-descr-footer);
    font-weight: 600;
  }

  &__divider {
    height: 1px;
    margin: 3rem 0 2.375rem 0;
    width: 100%;
    background-color: rgba(174, 184, 196, 0.1);
    @media screen and (min-width: $lg) {
      height: 3px;
      margin: 2.625rem 0 5rem 0;
    }

    &_top {
      margin: 2rem 0 2rem 0;
    }

    &_mobile {
      @media screen and (min-width: $lg) {
        display: none;
      }
    }
  }

  &__banner {
    margin-top: 6rem;

    &_no-mobile {
      display: none;
      @media screen and (min-width: $lg) {
        display: block;
      }
    }

    &_mobile {
      display: flex;
      justify-content: center;
      margin-top: 0;
      & img {
        max-width: 11rem;
      }
      @media screen and (min-width: $lg) {
        display: none;
      }
    }
  }
}

// footer-nav
.footer-nav {
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  text-align: center;
  @media screen and (min-width: $lg) {
    text-align: left;
    margin-bottom: 2.5rem;
  }

  &_no-mobile {
    display: none;
    @media screen and (min-width: $lg) {
      display: block;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin-bottom: 0.55rem;
    padding: 0;

    &:before {
      display: none;
    }
  }

  &__link {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-link);
    letter-spacing: -0.02em;
    text-decoration: none;
    transition: color ease 0.15s;
    @media screen and (min-width: $lg) {
      font-size: 1rem;
    }

      &:hover {
      color: var(--text-link-hover)
    }
  }
}


// footer-social
.footer-social {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  @media screen and (min-width: $lg) {
    justify-content: normal;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  &__link {
    text-decoration: none;
    margin-right: 0.75rem;
    max-width: 2.5rem;
    @media screen and (min-width: $lg) {
      max-width: 2.75rem;
    }

    &:last-child {
      margin-right: 0;
    }

    & svg {
      max-width: 100%;
    }

    &_twitter {
      fill: #2dace3;
      fill-rule: evenodd;
    }

    &_facebook {
      fill: #1d4e9b;
      fill-rule: evenodd;
    }

    &_instagramm path {
      fill: #4d4d4d;
      fill-rule: evenodd;
      filter: url(#filter);
    }
  }
}
