@import "~modern-css-reset/dist/reset.min.css";
@import "fonts";

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

:root {
  --bg-white: #fff;
  --bg-logo: #f67e07;
  --bg-primary: #0341c7;
  --bg-secondary: #f67e07;
  --text-white: #fff;
  --text-primary: #0341c7;
  --text-secondary: #f67e07;
  --text-link: #4989d6;
  --text-link-hover: #81bafe;
  --text-descr: #3879c8;
  --text-descr-footer: #aeb8c4;
  --text-descr-form: #3dfff5;
  --btn-primary: #f67e07;
  --btn-primary-hover: #fe9227;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: 'HK Grotesk';
  line-height: 1.6;
  color: var(--text-descr);
  font-weight: 500;
  @media screen and (min-width: $lg) {
    font-size: 14px;
  }

  @media screen and (min-width: $xxl) {
    font-size: 16px;
  }
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

img {
  max-width: 100%;
}

a {
  color: var(--text-secondary);
  &:hover {
    text-decoration: none;
  }
}

.title, h2 {
  color: var(--text-primary);
  font-family: 'Gilroy';
  font-size: 1.75rem;
  font-weight: 700;
  text-align: center;
  margin: 0 auto 3rem;
  line-height: 1.2;
  @media screen and (min-width: $lg) {
    max-width: 75%;
    font-size: 3rem;
    margin: 0 auto 4rem;
  }
}

.subtitle, h3 {
  color: var(--text-primary);
  font-family: 'Gilroy';
  font-size: 1.375rem;
  font-weight: 700;
  text-align: center;
  margin: 0 auto 2rem;
  line-height: 1.2;
  @media screen and (min-width: $lg) {
    max-width: 75%;
    font-size: 2rem;
    margin: 0 auto 3rem;
  }
}

input {
  &::placeholder {
    color: #9aa8c2;
  }
}


p {
  color: var(--text-descr);
  margin-bottom: 1rem;
  font-weight: 500;
}

// lists
ul {
  margin: 0 0 1rem 0;
  padding: 0;
  list-style: none;
  font-weight: 500;

  & li {
    color: var(--text-descr);
    font-weight: 500;
    padding: 0 0 0 1.5rem;

    &::before {
      content: "\2022";
      font-size: 1.5rem;
      color: var(--bg-secondary);
      font-weight: bold;
      display: inline-block;
      width: 1.5rem;
      margin-left: -1.5rem;
    }
  }
}

blockquote {
  padding-left: 2rem;
  position: relative;

  &::before {
    content: " ";
    display: inline-block;
    width: 3px;
    height: 100%;
    background-color: var(--bg-secondary);
    position: absolute;
    left: 0;
    top: 0;
  }
}

@import "./node_modules/bootstrap-4-grid/scss/grid";
@import "helpers";
@import "blocks/forms";
@import "blocks/btns";
@import "blocks/blocks";
@import "blocks/top";
@import "blocks/quotes";
@import "blocks/navigation";
@import "blocks/about";
@import "blocks/deposit";
@import "blocks/account";
@import "blocks/table";
@import "blocks/register";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/text-section";
@import "blocks/breadcrumbs";
@import "blocks/funding-slider";
@import "blocks/accordion";
@import "./node_modules/swiper/swiper-bundle";

@import "./node_modules/@splidejs/splide/dist/css/splide.min";

.swiper-pagination {
  position: unset;
  width: 100%;
  margin-top: -0.75rem;
  @media screen and (min-width: $lg) {
    display: none;
  }
}

.swiper-pagination-bullet {
  margin: 0 0.125rem;
}

.swiper-container-multirow-column > .swiper-wrapper {
  flex-direction: inherit;
}

// preloader
.preloader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background: #fafafa;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;

  & svg {
    fill: var(--bg-secondary);
    position: absolute;
    width: 48px;
    height: 48px;
    top: 16px;
    left: 16px;
  }

  &_hide {
    display: none;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--bg-primary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



// slider
.slider {
  border-radius: 20px;
  overflow: hidden;
  & .swiper {
    width: 100%;
    //height: 100%;
  }

  & .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  & .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    //object-fit: cover;
  }

  &-control {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-top: 1rem;
  }

  &-pagination {
    color: var(--text-primary);
    text-align: center;
    width: 5rem;
    font-weight: 500;
  }

  &-button {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--bg-secondary);
    background-color: var(--bg-secondary);
    transition: all ease 0.3s;
    @media screen and (min-width: $md) {
      width: 3rem;
      height: 3rem;
    }

    & svg {
      fill: #fcf0a3;
      transition: all ease 0.3s;
    }

    &-next {
      transform: rotate(180deg);
    }

    &:hover {
      background-color: transparent;

      & svg {
        fill: var(--text-primary);
      }
    }
  }
}
