// table
.table {
  background-color: #f7f9fd;
  width: 100%;
  border-radius: 1rem;
  padding: 2rem;
  text-align: left;

  &_overflow {
    white-space: nowrap;
    overflow-y: auto;

    & th, & td {
      padding-right: 1.25rem !important;
    }
  }

  &__th-m-s {
    @media screen and (max-width: $sm) {
      font-size: 0.625rem !important;
    }
  }

  & table {
    width: 100%;
    border-collapse: collapse;
  }

  & thead {
    & tr {
      & th {
        font-family: 'Gilroy';
        color: var(--text-primary);
        font-size: 1rem;
        padding: 1rem 0;
        @media screen and (min-width: $lg) {
          font-size: 1.5rem;
          padding: 1.25rem 0;
        }
      }
    }
  }

  & tbody {
    & tr {
      & th {
        font-family: 'Gilroy';
        color: var(--text-primary);
        font-size: 0.825rem;
        //text-align: left;
        white-space: nowrap;
        @media screen and (min-width: $lg) {
          font-size: 1.5rem;
        }
      }

      & td {
        color: #3879c8;
        font-size: 0.75rem;
        font-weight: 500;
        //text-align: center;
        @media screen and (min-width: $lg) {
          font-size: 1rem;
        }
      }

      & td, & th {
        padding: 1rem 0;
        border-bottom: 1px solid #7ca1d7;
        @media screen and (min-width: $lg) {
          padding: 1.25rem 0;
        }
      }

      &:last-child {
        & td, & th {
          border-bottom: none;
        }
      }
    }
  }
}
