// breadcrumbs
.breadcrumbs {
  &__list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    list-style: none;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    position: relative;

    &:before {
      content: '/';
      position: absolute;
      right: 0.5rem;
      top: 0;
      margin: 0;
      padding: 0;
      width: auto;
      font-size: inherit;
      line-height: 1.2;
    }

    &_current {
      color: var(--text-secondary);
      &:before {
        display: none;
      }
    }
  }

  &__link {
    color: var(--text-descr);
    text-decoration: none;
    display: inline-block;
    padding-right: 1.5rem;
  }
}
