// navigation
.navigation {
  display: none;
  align-items: center;
  @media screen and (min-width: $xl) {
    display: flex;
  }

  &__list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    color: var(--text-white);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    display: block;
    position: relative;

    &:before {
      content: ' ';
      position: absolute;
      width: 0;
      bottom: -0.5rem;
      height: 3px;
      background-color: var(--bg-secondary);
    }

    &:hover {
      &:before {
        width: 100%;
      }
    }
  }

  &__item {
    padding: 0 0 0 1.5rem;
    @media screen and (min-width: 1700px) {
      padding: 0 0 0 2rem;
    }

    &:last-child {
      padding-right: 0;
    }

    &:before {
      display: none;
    }
  }

  &__item_active &__link:before {
    width: 100%;
  }
}

.mobile-navigation {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  max-width: 30rem;
  padding: 4rem 2rem;
  background-color: var(--bg-white);
  z-index: 10;
  text-align: center;
  transform: translateX(-31rem);
  transition: transform ease 0.2s;
  @media screen and (min-width: $xl){
    display: none;
  }

  &_open {
    transform: translateX(0);
  }

  &__logo {
    display: flex;
    justify-content: center;

    & svg {
      width: 2.5rem;
    }

    & span {
      font-size: 1.5rem;
    }
  }

  &__list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 2rem 0 3rem;
  }

  &__item {
    padding: 0;
    margin-bottom: 0.75rem;

    &:before {
      display: none;
    }
  }

  &__link {
    font-family: 'Gilroy';
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--text-primary);
    opacity: 0.6;
    text-decoration: none;
    transition: opacity ease 0.15s;
    @media screen and (min-width: $sm) {
      font-size: 1.125rem;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__signup {
    padding: 0.875rem 2.75rem;
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  &__login {
    font-size: 1rem;
    padding: 0.5rem 1.75rem;
    background-color: var(--bg-white);
    color: var(--text-primary);
    border: 2px solid #e3e9f1;

    &:hover {
      background-color: var(--bg-white);
      color: var(--text-primary);
    }
  }

  &__close {
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    border: 2px solid #b3c6ee;
    border-radius: 50%;
    padding: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.4;
    @media screen and (min-width: $md) {
      padding: 0.5rem;
      right: 1.75rem;
      top: 1.75rem;
    }
  }
}

.close-icon {
  position: relative;
  width: 30px;
  height: 30px;

  &:before, &:after {
    position: absolute;
    left: 13px;
    top: 2px;
    content: ' ';
    height: 26px;
    width: 4px;
    background-color: var(--bg-primary);
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.overlay {
  opacity: 0;
  transform: translateX(-101vw);
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .6);
  width: 100%;
  height: 100vh;
  z-index: 8;
  cursor: pointer;
  transition: opacity ease 0.4s;

  &_open {
    opacity: 1;
    transform: translateX(0);
    @media screen and (min-width: $xl){
      display: none;
    }
  }
}
