// btns
.btn {
  color: var(--text-white);
  background-color: var(--btn-primary);
  transition: all ease 0.15s;
  font-family: 'Gilroy';
  font-weight: 700;
  outline: none;
  border: none;
  border-radius: 48px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;

  & svg {
    fill: #fcf0a3;
    fill-rule: evenodd;
    margin-left: 0.75rem;
    max-width: 0.375rem;
    margin-bottom: -0.125rem;
  }

  &_big {
    font-size: 1rem;
    padding: 0.75rem 1.75rem;
    @media screen and (min-width: $lg) {
      font-size: 1.25rem;
      padding: 1rem 3rem;
    }
  }

  &_small {
    font-size: 0.625rem;
    padding: 0.75rem 1rem;
    text-transform: uppercase;
    text-decoration: none;
    @media screen and (min-width: $lg) {
      font-size: 1rem;
      letter-spacing: -0.04rem;
      padding: 1.125rem 2.5rem;
    }
  }

  &_xsmall {
    font-size: 0.625rem;
    padding: 0.5rem 0.75rem;
    text-transform: uppercase;
    text-decoration: none;
    @media screen and (min-width: $lg) {
      font-size: 0.875rem;
      letter-spacing: -0.04rem;
      padding: 1rem 2rem;
    }
  }

  &_dl {
    font-size: 0.938rem;
    letter-spacing: -0.04rem;
    padding: 0.625rem 2.5rem;
    text-decoration: none;
    @media screen and (min-width: $lg) {
      font-size: 1.25rem;
      padding: 0.875rem 4.5rem;
    }

    & span {
      display: block;
      text-transform: uppercase;
      font-size: 0.5rem;
      color: #fcf0a3;
      letter-spacing: 0.06rem;
      @media screen and (min-width: $lg) {
        font-size: 0.875rem;
      }
    }
  }

  &:hover {
    background-color: var(--btn-primary-hover);
  }
}
