.lay {
  position: relative;
}

.overflow {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  //padding: 0 35px;
  @media screen and (min-width: $lg) {
    padding: 0 20px;
  }

  &_big {
    max-width: 1500px;
  }
}

// helpers
$m: 0, 1, 2, 3, 4, 5;

// margin
@each $size in $m {
  .mt-#{$size} {
    margin-top: #{$size}rem !important;
  }
}

@each $size in $m {
  .mb-#{$size} {
    margin-bottom: #{$size}rem !important;
  }
}

@each $size in $m {
  .ml-#{$size} {
    margin-left: #{$size}rem !important;
  }
}

@each $size in $m {
  .mr-#{$size} {
    margin-right: #{$size}rem !important;
  }
}

// padding
@each $size in $m {
  .pt-#{$size} {
    padding-top: #{$size}rem !important;
  }
}

@each $size in $m {
  .pb-#{$size} {
    padding-bottom: #{$size}rem !important;
  }
}

@each $size in $m {
  .pl-#{$size} {
    padding-left: #{$size}rem !important;
  }
}

@each $size in $m {
  .pr-#{$size} {
    padding-right: #{$size}rem !important;
  }
}

.p_s {
  padding: 3.5rem 0;
  @media screen and (min-width: $lg) {
    padding: 7rem 0;
  }
}

$positions: 'left', 'center', 'right';
@each $position in $positions {
  .text-#{$position} {
    text-align: #{$position} !important;
  }
}

.text-secondary {
  color: var(--text-secondary) !important;
}
