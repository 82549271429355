// modal
.modal {
  border-radius: 20px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  direction: ltr;
  margin: 0;
  padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0) env(safe-area-inset-bottom, 0) env(safe-area-inset-left, 0);
  box-sizing: border-box;
  //display: flex;
  flex-direction: column;
  color: var(--fancybox-color, #fff);
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  z-index: 1050;
  outline: none;
  transform-origin: top left;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  --carousel-button-width: 48px;
  --carousel-button-height: 48px;
  --carousel-button-svg-width: 24px;
  --carousel-button-svg-height: 24px;
  --carousel-button-svg-stroke-width: 2.5;
  --carousel-button-svg-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));

  &_opened {
    display: flex;
  }

  &__overlay {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__content {
    width: 100%;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 15px;
    position: relative;
    z-index: 11;
  }

  &_sm &__content {
    max-width: $sm;
  }

  &_md &__content {
    max-width: $md;
  }

  &_xl &__content {
    max-width: $xl;
  }

  &_xxl &__content {
    max-width: $xxl;
  }

  & .carousel__button.is-close {
    display: none;
  }
}
